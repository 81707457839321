<template>
  <v-app style="background: #ebedef">
    <!--  Side Bar  -->
    <v-navigation-drawer
      :permanent="$vuetify.breakpoint.mdAndUp"
      :mini-variant="$vuetify.breakpoint.mdAndUp ? mini : false"
      dark
      class="px-1 primary"
      app
      v-model="drawer"
    >
      <v-list-item two-line style="padding: 0 6px">
        <v-list-item-avatar>
          <img :src="profilePicture" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>{{
            `${!is_admin ? user.profile.FirstName : user.name}`
          }}</v-list-item-title>
          <v-list-item-subtitle class="overline" v-if="!is_admin"
            >{{ memberCategory }}
            {{ user.profile.MemberShipType == "CONSULTING" ? "Engineer" : "" }}
          </v-list-item-subtitle>
          <v-list-item-subtitle v-else>{{ user.email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list
        nav
        class="pa-0"
        v-for="item in is_admin ? adminLinks : navLinks"
        :key="item.title"
      >
        <v-list-item-group v-model="selectedItem">
          <v-list-item
            :href="item.href"
            :target="item.target"
            :to="item.to"
            v-if="!item.subs"
            :disabled="item.disabled"
          >
            <v-list-item-icon>
              <v-icon :disabled="item.disabled">{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>

        <v-list-group :value="false" v-if="item.subs" v-model="selectedItem">
          <template v-slot:activator>
            <v-list-item-icon>
              <v-icon color="white">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="white--text">
              {{ item.title }}
            </v-list-item-title>
          </template>

          <v-list-item
            v-for="(sub, idx) in item.subs"
            :key="idx"
            :to="sub.to"
            class="white--text"
            item
          >
            <v-list-item-icon class="ml-10">
              <v-icon color="white">{{ sub.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="ml-1">{{ sub.title }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
      </v-list>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn
            class="white--text text-capitalize"
            :block="!mini"
            :text="!mini"
            @click="$store.dispatch('Auth/logout')"
            :icon="mini"
          >
            <v-icon>logout</v-icon> <span v-if="!mini">Logout</span>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <!--  App Bar  -->
    <v-app-bar app elevation="0">
      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.smAndDown"
        @click="drawer = !drawer"
      />

      <v-app-bar-nav-icon
        v-if="$vuetify.breakpoint.mdAndUp"
        @click="mini = !mini"
      />

      <v-toolbar-title class="overline" v-if="!is_admin">
        REGISTRATION NO:
        <span class="secondary--text mr-2">{{
          member ? member.No : "--"
        }}</span>
        CATEGORY:
        <span class="secondary--text mr-2">{{ memberCategory }}</span>
        MEMBERSHIP STATUS:
        <v-chip color="secondary" x-small class="overline"
          >{{ member ? member.Status : "--" }}
        </v-chip>
      </v-toolbar-title>

      <v-spacer />
      <v-toolbar-title class="overline" v-if="!is_admin">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :to="{ name: 'profileConfirm' }"
              v-if="
                user.profile.confirmed === false &&
                isDataLoaded &&
                application[0].status == 'Released'
              "
              text
              color="red"
              class="ml-2"
              v-bind="attrs"
              v-on="on"
            >
              Click to confirm profile name !</v-btn
            >
          </template>
          <span>Please Confirm if your profile name is correct</span>
        </v-tooltip>
      </v-toolbar-title>
      <v-spacer />

      <notification />

      <v-menu :nudge-width="150" offset-x>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on" large>
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </template>

        <v-card tile>
          <v-card-title class="overline">My account</v-card-title>
          <v-divider></v-divider>

          <v-list>
            <v-list-item-group>
              <v-list-item :to="{ name: 'Settings' }">
                <v-list-item-icon>
                  <v-icon left>mdi-cog</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Settings</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item
                v-if="isGoodStanding"
                :to="{
                  name: 'Document',
                  params: { code: encodeRoute(user.profile.MemberNo) },
                }"
              >
                <v-list-item-icon>
                  <v-icon left>mdi-file</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ minorSettings.certificate_of_good_standing_caption }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item dense @click="$store.dispatch('Auth/logout')">
                <v-list-item-icon>
                  <v-icon left>mdi-power</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-body-2"
                    >Logout</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-divider />

    <v-main>
      <!-- Content-->

      <v-sheet
        color="grey lighten-4"
        class="py-1 px-5 mb-3 d-flex flex-md-row flex-column justify-center justify-md-space-between align-center"
      >
        <v-breadcrumbs :items="crumbs">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>

          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              :href="item.href"
              class="text-capitalize"
              disabled
            >
              {{ item.text }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>

        <div>
          <v-alert
            prominent
            dense
            text
            type="error"
            v-if="!isProfileComplete && !is_admin"
          >
            <template v-slot:prepend>
              <v-progress-circular
                :size="60"
                :width="8"
                :value="completedChecklist"
                class="mr-3"
                :color="completedChecklist < 50 ? 'primary' : 'secondary'"
              >
                {{ Math.round(completedChecklist) }}%
              </v-progress-circular>
            </template>
            <!-- <v-row align="center" no-gutters>
              <v-col class="grow">
                Incomplete profile ! Click to continue
              </v-col>
              <v-col class="shrink">
                <v-btn
                  small
                  dark
                  icon
                  class="ml-1"
                  :to="{ name: 'listing', params: { type: 'Application' } }"
                >
                  <v-icon color="error">mdi-arrow-right</v-icon>
                </v-btn>
              </v-col>
            </v-row> -->
            <v-tooltip bottom v-if="!isProfileComplete">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :to="{ name: 'listing', params: { type: 'Application' } }"
                  text
                  color="red"
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  Incomplete profile !</v-btn
                >
              </template>
              <span>Click to complete you profile</span>
            </v-tooltip>
          </v-alert>

          <v-btn v-if="false" text color="secondary" class="ml-2" outlined>
            action</v-btn
          >
        </div>
      </v-sheet>

      <router-view name="view" />
    </v-main>

    <spinner />

    <confirmation />

    <Footer />
  </v-app>
</template>

<script>
import Footer from "../../components/Footer";
import Spinner from "../../plugins/loader/views/Spinner";
import { AuthService } from "../../modules/auth";
import Confirmation from "../../plugins/confirmtion/Confirmation";
import SetupMixin from "../../modules/setup/SetupMixin";
import Notification from "../../modules/dashboard/components/Notification";
import AdminLinks from "../../modules/admin/AdminLinks";
import RouterMixin from "../../router/RouterMixin";

export default {
  name: "DashboardLayout",
  mixins: [SetupMixin, RouterMixin],
  components: { Notification, Confirmation, Spinner, Footer },
  data: () => ({
    drawer: null,
    mini: false,
    selectedItem: 0,
  }),

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Admin/getSettings");
      v.$store.dispatch("Auth/getTerms");
      if (AuthService.user.is_admin) {
        if (AuthService.user.email === "admin@member.com") {
          v.$router.push({
            name: "AdminSettings",
          });
        } else {
          v.$router.push({
            name: "AdminUsers",
          });
        }
      } else {
        if (!AuthService.user.profile && !AuthService.user.is_admin) {
          v.$router.push({ name: "initiate" });
        }
        v.$store.dispatch(
          "Application/checklist",
          AuthService.user.profile.MemberShipType === ""
            ? AuthService.user.category
            : AuthService.user.profile.MemberShipType
        );
        v.$store.dispatch("Application/getApplications");
        v.$store.dispatch("Subscriptions/getSubs");
      }
    });
  },

  mounted() {
    Event.$on("application-checklist", () => {
      this.$store.dispatch(
        "Application/checklist",
        AuthService.user.profile.MemberShipType === ""
          ? AuthService.user.category
          : AuthService.user.profile.MemberShipType
      );
    });
  },

  computed: {
    items() {
      return [
        {
          to: "/dashboard",
          icon: "mdi-gauge",
          title: "Dashboard",
          package: "Dashboard",
          disabled: false,
        },
        {
          to: "/profile",
          title: "Profile",
          package: "Profile",
          icon: "mdi-account-details",
          disabled: false,
        },
        {
          to: "/cpd",
          title: "CPD",
          package: "CPD",
          icon: "mdi-school",
          disabled: this.member ? false : true,
        },
        {
          to: `/dashboard/goodStanding/${this.encodeRoute(
            this.user.profile.MemberNo
          )}`,
          title: "Certificate",
          package: "Certificate",
          icon: "mdi-certificate-outline",
          disabled: !this.checkApplication && !this.isGoodStanding,
          subs: [
            {
              to: `/dashboard/goodStanding/${this.encodeRoute(
                this.user.profile.MemberNo
              )}`,
              title: this.minorSettings.certificate_of_good_standing_caption,
              package: "Certificate",
              icon: "mdi-file",
              disabled: this.isGoodStanding ? false : true,
            },
            {
              to:
                this.application.length !== 0
                  ? `/application/certificate/${this.application[0].Code}`
                  : null,
              title: this.minorSettings.acceptance_letter_caption
                ? this.minorSettings.acceptance_letter_caption
                : "Graduate Certificate",
              package: "Certificate",
              icon: "mdi-file-document",
              disabled: this.checkApplication ? false : true,
            },
          ],
        },
        {
          to: "/application",
          title: "Application",
          package: "Application",
          icon: "mdi-file-document",
          disabled: false,
        },
        {
          to: "",
          href: this.terms
            ? `${this.terms.projectURL}?accesstoken=${AuthService.token}`
            : "",
          title: "My Projects",
          target: "_blank",
          package: "MyProjects",
          icon: "mdi-account-hard-hat",
          disabled: this.isProjects ? false : true,
        },
        {
          to: "/subscription",
          title: "Subscription",
          package: "Subscription",
          icon: "mdi-account-reactivate",
          disabled: this.member ? false : true,
        },
        {
          to: "/upgrade",
          title: "Upgrade Application",
          package: "Upgrade",
          icon: "mdi-transfer-up",
          disabled: this.member ? false : true,
        },
        {
          to: "/license",
          title: `${this.licenseCaption}`,
          package: "License",
          icon: "mdi-file-certificate",
          disabled: false,
        },
        {
          to: "/billing",
          title: "Billing",
          package: "Billing",
          icon: "mdi-receipt",
          disabled: false,
        },
        // {
        //   to: "/payment-history",
        //   title: "Payment History",
        //   package: "Payment History",
        //   icon: "mdi-receipt",
        //   disabled: false,
        // },
        {
          to: "/events",
          title: "Events",
          package: "Events",
          icon: "mdi-ticket-confirmation",
          disabled: false,
        },
      ];
    },
    terms() {
      return this.$store.getters["Auth/terms"][0];
    },
    is_admin() {
      return AuthService.user.is_admin;
    },
    crumbs() {
      let pathArray = this.$route.path.split("/");

      pathArray.shift();

      return pathArray.reduce((breadcrumbArray, path, idx) => {
        breadcrumbArray.push({
          path: path,
          href: breadcrumbArray[idx - 1]
            ? "/" + breadcrumbArray[idx - 1].path + "/" + path
            : "/" + path,
          text: this.$route.matched[idx]
            ? this.$route.matched[idx].name || path
            : path,
        });
        return breadcrumbArray;
      }, []);
    },
    profilePicture() {
      let profile = AuthService.user.profile;

      return profile
        ? profile.picture
          ? `data:image/png;base64,${profile.picture}`
          : "/img/avatar.jpg"
        : "/img/avatar.jpg";
    },
    user() {
      return AuthService.user;
    },
    isGoodStanding() {
      return (
        !this.is_admin &&
        this.minorSettings.certificate_of_good_standing &&
        this.user.profile.InGoodStanding &&
        this.user.profile.MemberNo !== ""
      );
    },
    checkApplication() {
      return this.application.length !== 0
        ? this.application[0].assignedMemberNo !== "" &&
          this.application[0].status === "Released"
          ? true
          : false
        : false;
    },
    member() {
      return !this.is_admin
        ? AuthService.user.profile.memberInformation.shift()
        : null;
    },
    isProjects() {
      return this.member ? this.memberCategory !== "GRADUATE" : false;
    },
    application() {
      return this.$store.getters["Application/applications"];
    },
    isDataLoaded() {
      const appdata = this.application[0] !== undefined;
      return appdata;
    },
    checklist() {
      return this.$store.getters["Checklist/checklist"];
    },
    isProfileComplete() {
      let isComplete = true;
      this.checklist.forEach((check) => {
        if (!check.pass) isComplete = false;
      });

      return isComplete;
    },
    completedChecklist: function () {
      const completed = this.checklist.filter((check) => check.pass).length;
      return (completed / this.checklist.length) * 100;
    },
    memberCategory() {
      if (!this.is_admin) {
        const member = this.membershipCategories.filter(
          (item) => item.Code === this.user.profile.MemberShipType
        )[0];
        return member ? member.Description : "";
      }
      return null;
    },
    adminLinks: function () {
      const links = [...AdminLinks];
      return this.user.email === "admin@member.com"
        ? links
        : links.filter((el) => el.type !== "admin");
    },
    settings() {
      return this.$store.getters["Admin/adminGetters"]("settings");
    },
    minorSettings() {
      return this.settings.minor_settings
        ? JSON.parse(this.settings.minor_settings)
        : "";
    },
    licenseCaption() {
      return this.minorSettings
        ? this.minorSettings.license_alternative_caption
        : "License";
    },
    navLinks() {
      if (this.settings && this.settings.site_installed_packages)
        return this.items
          .filter(
            (item) =>
              JSON.parse(this.settings.site_installed_packages).includes(
                item.package
              ) && !item.disabled
          )
          .map((item) => {
            if (item.subs) {
              const subs = item.subs.filter((sub) => !sub.disabled);
              return { ...item, subs };
            }
            return item;
          });
      return this.items.filter((item) => item.title === "Dashboard");
    },
  },
  watch: {
    settings: {
      handler: async function (val) {
        if (val) {
          if (val.site_colors) {
            const colors = JSON.parse(val.site_colors);
            colors.forEach((el) => {
              this.$vuetify.theme.themes.light[el.colorName] = el.color;
            });
          }
        }
      },
      deep: true,
    },
    "$route.name": function (val) {
      if (val === "AdminUsers" || val === "AdminSettings") {
        this.mini = true;
      }
    },
  },
};
</script>

<style scoped></style>
