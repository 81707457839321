<template>
  <v-container>
    <v-row v-if="document">
      <v-col cols="12" md="8" offset-md="2">
        <v-card class="my-5">
          <v-card-title>
            <v-spacer />
            <v-btn small color="secondary" @click="downloadFile(document)">
              <v-icon left>mdi-download</v-icon>
              Download
            </v-btn>
          </v-card-title>

          <pdf
            style="width: 100%"
            :src="`data:application/pdf;base64,${document}`"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" md="8" offset-md="2">
        <v-card class="my-5">
          <v-card-title>
            <v-spacer />
            <v-skeleton-loader type="button" />
          </v-card-title>

          <div>
            <v-skeleton-loader type="card" />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import pdf from "vue-pdf";
import RouterMixin from "../../../router/RouterMixin";
import FileMixin from "../../../mixins/FileMixin";

export default {
  name: "Document",
  components: { pdf },
  mixins: [RouterMixin, FileMixin],
  data: () => ({}),

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch(
        "License/certificateOfGoodStanding",
        v.decodeRoute(v.$route.params.code)
      );
    });
  },

  computed: {
    document() {
      return this.$store.getters["License/licenseGetters"](
        "certificateOfGoodStanding"
      )[0];
    },
  },
};
</script>
