import { render, staticRenderFns } from "./RejectionLetter.vue?vue&type=template&id=358f756b&scoped=true&"
import script from "./RejectionLetter.vue?vue&type=script&lang=js&"
export * from "./RejectionLetter.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "358f756b",
  null
  
)

export default component.exports