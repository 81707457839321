import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"8","offset-md":"2"}},[_c(VCard,{staticClass:"my-5"},[_c(VCardTitle,[_c(VBtn,{attrs:{"text":"","small":""},on:{"click":function($event){return _vm.$router.back()}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-arrow-left")]),_vm._v(" Back ")],1),_c(VSpacer),_c(VBtn,{staticClass:"mr-2",attrs:{"small":"","color":"secondary"},on:{"click":function($event){_vm.downloadFile(_vm.invoice, ("INVOICE-" + (_vm.currentDate()) + ".pdf"))}}},[_c(VIcon,{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v(" Download ")],1),(_vm.service && !_vm.service.postedReceiptNo)?_c(VBtn,{attrs:{"small":"","color":"secondary"},on:{"click":function($event){return _vm.pay(_vm.service)}}},[_vm._v(" Pay Now "),_c(VIcon,{attrs:{"right":""}},[_vm._v("mdi-arrow-right")])],1):_vm._e()],1),(_vm.isInvoice)?_vm._l((_vm.pageCount(_vm.invoice)),function(i){return _c(VCardText,{key:i,staticClass:"elevation-1 mb-1"},[_c('pdf',{staticStyle:{"width":"100%"},attrs:{"src":("data:application/pdf;base64," + _vm.invoice),"page":i}})],1)}):_c(VCardText,[_c(VSkeletonLoader,{staticClass:"mx-auto",attrs:{"max-width":"500","type":"image, article"}}),_c('span',[_vm._v(" Please wait....")])],1)],2)],1)],1),_c('checkout')],1)}
var staticRenderFns = []

export { render, staticRenderFns }