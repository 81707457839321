import { render, staticRenderFns } from "./LicenseDocument.vue?vue&type=template&id=4a74f850&scoped=true&"
import script from "./LicenseDocument.vue?vue&type=script&lang=js&"
export * from "./LicenseDocument.vue?vue&type=script&lang=js&"
import style0 from "./LicenseDocument.vue?vue&type=style&index=0&id=4a74f850&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a74f850",
  null
  
)

export default component.exports