<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="8" offset-md="2">
        <v-card class="my-5">
          <v-card-title>
            <v-btn text small @click="$router.back()">
              <v-icon left>mdi-arrow-left</v-icon>
              Back
            </v-btn>
            <v-spacer />
            <v-btn
              small
              color="secondary"
              @click="downloadFile(receipt, `RECEIPT-${currentDate()}.pdf`)"
            >
              <v-icon left>mdi-download</v-icon>
              Download
            </v-btn>
          </v-card-title>
          <template v-if="isReceipt">
            <v-card-text
              class="elevation-1 mb-1"
              v-for="i in pageCount(receipt)"
              :key="i"
            >
              <pdf
                v-if="receipt"
                style="width: 100%"
                :src="`data:application/pdf;base64,${receipt}`"
                :page="i"
              />
              <p else>No Receipt Found!</p>
            </v-card-text>
          </template>
          <v-card-text v-else>
            <v-skeleton-loader class="mx-auto" max-width="500" type="card">
            </v-skeleton-loader>
            <span> Please wait....</span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import pdf from "vue-pdf";
import FileMixin from "../../../mixins/FileMixin";
import DateMixin from "../../../mixins/DateMixin";
import RouterMixin from "../../../router/RouterMixin";

export default {
  name: "Receipt",
  mixins: [FileMixin, DateMixin, RouterMixin],
  components: { pdf },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("Billing/getReceipt", v.decodeRoute(to.params.code));
    });
  },
  computed: {
    receipt() {
      return this.$store.getters["Billing/receipt"];
    },
    isReceipt() {
      return this.receipt || this.receipt !== "";
    },
  },
};
</script>

<style scoped></style>
