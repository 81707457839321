<template>
  <v-container class="mt-5">
    <v-data-table :headers="headers" :items="licenses" sort-by="calories" dense>
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="text-h6">
            {{ minorSettings.license_alternative_caption }} Applications
          </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            depressed
            outlined
            dark
            :to="{ name: 'licenseCard' }"
          >
            <v-icon class="mr-2">mdi-playlist-plus</v-icon>
            New {{ minorSettings.license_alternative_caption }}
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          x-small
          outlined
          color="secondary"
          :to="{
            name: 'licenseDocument',
            params: { code: item.licenseApplicationNo },
          }"
        >
          view
          <v-icon color="secondary" right small> mdi-arrow-right </v-icon>
        </v-btn>

        <v-btn
          x-small
          outlined
          color="secondary"
          v-if="item.proFormaRef !== ''"
          @click="
            $root.routeTo({
              name: 'invoice',
              params: { code: item.licenseApplicationNo },
            })
          "
          class="ml-2"
        >
          {{ item.receiptNo === "" ? "Proforma" : "Invoice" }}
          <v-icon color="secondary" right small> mdi-file-document </v-icon>
        </v-btn>

        <v-btn
          @click="pay(item)"
          x-small
          outlined
          color="secondary"
          class="ml-2"
          v-if="item.receiptNo === '' && item.licenseBase64 !== ''"
        >
          <span class="text-white">
            Pay Now
            <v-icon>mdi-cash-multiple</v-icon>
          </span>
        </v-btn>

        <v-btn
          x-small
          outlined
          color="secondary"
          v-if="item.receiptNo !== ''"
          @click="
            $root.routeTo({
              name: 'receipt',
              params: { code: encodeRoute(item.receiptNo) },
            })
          "
          class="mlm-2"
        >
          <span class="text-white">
            Receipt
            <v-icon small>mdi-receipt</v-icon>
          </span>
        </v-btn>
      </template>
    </v-data-table>
    <Checkout />
  </v-container>
</template>

<script>
import Checkout from "@kinetics254/paygate/src/views/Checkout.vue";
export default {
  name: "LicenseList",
  components: {
    Checkout,
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    desserts: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
  }),

  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("License/getLicenseApplications");
    });
  },

  computed: {
    licenses() {
      return this.$store.getters["License/licenseGetters"](
        "licenseApplications"
      );
    },

    headers() {
      return [
        {
          text: "Application No",
          value: "licenseApplicationNo",
          sortable: true,
          align: "center",
        },
        {
          text: "Application Date",
          value: "applicationDate",
          sortable: true,
          align: "center",
        },
        {
          text: "Description",
          value: "description",
          sortable: true,
          align: "center",
        },
        {
          text: "Amount",
          value: "amount",
          sortable: true,
          align: "center",
        },
        {
          text: "Type",
          value: "documentType",
          sortable: true,
          align: "center",
        },
        {
          text: "Status",
          value: "status",
          sortable: true,
          align: "center",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: true,
          align: "center",
        },
      ];
    },

    settings() {
      return this.$store.getters["Admin/adminGetters"]("settings");
    },

    minorSettings() {
      return this.settings.minor_settings
        ? JSON.parse(this.settings.minor_settings)
        : [];
    },
  },

  methods: {
    pay: function (license) {
      let payload = {
        payerID: license.profileID,
        sourceDocumentNo: license.licenseApplicationNo,
        description: "License fees",
        amount: license.amount,
        accountNo: "",
        success_url: `${process.env.VUE_APP_URL}/${
          this.$router.resolve({ name: "LicenseList" }).href
        }`,
        fail_url: window.location.href,
      };

      payload = btoa(JSON.stringify(payload));
      // window.location.href = `${process.env.VUE_APP_PAYGATE_CHECKOUT_URL}/?payload=${payload}`;
      this.$checkout.pay(payload);
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>

<style scoped></style>
