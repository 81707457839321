import { render, staticRenderFns } from "./Upgrade.vue?vue&type=template&id=3bf8be3f&scoped=true&"
import script from "./Upgrade.vue?vue&type=script&lang=js&"
export * from "./Upgrade.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bf8be3f",
  null
  
)

export default component.exports